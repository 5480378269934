@tailwind base;
@tailwind components;
@tailwind utilities;






:root {
  font-size: 110%;
}

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
 
   
}

html,
body,
#root {
  height: 100vh;
 
}

* {
  padding: 0;
  margin: 0;
  outline: 0;
  list-style: none;
  text-decoration: none; 
}

body,
input,
button,
textarea {
  font: 400 1em Nunito;

  color: #0D0D0D;
}

.xbackground {
  min-height: 100vh; 
  background: linear-gradient(to bottom, #f9f7f3, #fdfdfb);
}


@media (min-width: 700px) {
  :root {
    font-size: 110%;
  }
}

@media (min-width: 980px) {
  :root {
    font-size: 120%;
  }
}

@media (min-width: 1400px) {
  :root {
    font-size: 130%;
  }
}