.lifoto {
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    border-radius: 5rem;
}

.lp {

    color: #0D0D0D;
    font-weight: 200;
    font-size: 1em;
}

.lestilo {

    color: #0D0D0D;
    font-weight: 200;
    font-size: 1em;
}

.ico {
    color: gray;
}

.cbaixo2 {
    border-color: #221445;
    border-style: solid;
    border-width: 0px 0px 2px 0px;

}

.liconteudo {

    padding: .5rem;
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 16rem;
    height: 9rem;
    z-index: 0;
    transition: 0.5s;
    border-radius: 8px;
}

.lifundo:hover {

    z-index: 1;
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

.lilabel {

    color: #0D0D0D;

    font-size: 1.4em;
    font-family: 'Ubuntu', cursive;
}

.f {
    min-width: 16rem;
    min-height: 2rem;
    padding-left: 1em;
}

.lfr {
    display: flex;
    flex-direction: row;
}

.lfc {
    display: flex;
    flex-direction: column;
}

.ldx {
    background-color: rgba(0, 0, 0, 0);
    padding-right: 1em;
}

.lf {
    display: flex;
}

.lfcenter {
    justify-content: space-between;
}

.tverde {
    color: #12C15D;
}

.tvermelho {
    color: #C12A12;
}

.tazul {
    color: #221445;
}

.tamarelo {
    color: #FCDB35;
}

.tcinza {
    color: gray;
}