.carousel-container {
  display: flex;
  overflow-x: scroll;
  padding: 10px;
}

.carousel-item {
  min-width: 200px;
  margin-right: 10px;
  text-align: center;
}

.carousel-item img {
  width: 100%;
  border-radius: 10px;
}

.video-title {
  margin-top: 5px;
  font-size: 14px;
}




.category-list-container {
  margin-top: 20px;
}

.category-item {
  margin-bottom: 20px;
}

.category-item h2 {
  margin-bottom: 10px;
}

.videos {
  display: flex;
  overflow-x: scroll;
}

.video-item {
  min-width: 16rem;
  margin-right: 10px;
  text-align: center;
}

.video-item img {
  width: 16rem;
  height: 9rem;
  object-fit: cover;
  border-radius: 8px;
}

.video-title {
  margin-top: 5px;
  font-size: 14px;
}