.carousel img {
  width: 16rem;
  height: 9rem;
}

.channel-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.channel-item {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  flex: 1 1 calc(33.333% - 20px);
  box-sizing: border-box;
}

.channel-item h3 {
  margin: 0 0 10px;
}

.channel-item p {
  margin: 0;
}