.cconteudo {
    padding: 1rem;
}

.xtudo {
    margin-left: 2rem;
    margin-right: 2rem;
}



.cfoto {
    width: 8rem;
    height: 8rem;
    object-fit: cover;
    border-radius: 8rem;
}

.cflr {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.cflre {
    justify-content: space-between;
}

.cflc {
    display: flex;
    flex-direction: column;
}

.cluser {
    color: #0D0D0D;


    font-size: 1.4em;
    font-family: 'Ubuntu', cursive;

}

.clestilo {

    color: #0D0D0D;
    font-weight: 200;
    font-size: 1em;

}

.cmargint {
    margin-top: 1rem;
}

.cmarginl {
    margin-left: 1rem;
}

.cmarginr {
    margin-right: 1rem;
}

.cmarginb {
    margin-bottom: 1rem;
}

.cbaixo {
    margin-top: 1rem;
    border-color: #0D0D0D;
    border-style: solid;
    border-width: 1px 0px 0px 0px;
}

.cflr2 {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    align-items: center;
}

.cflr2a {
    display: flex;
    flex-direction: row;
}

.cflr2b {
    margin-left: 1rem;
    border-color: #0D0D0D;
    border-style: solid;
    border-width: 0px 0px 0px 1px;
    padding-left: 1rem;
}

.clp {
    white-space: pre-line;
    padding: 1rem; 
    line-height: 1.6;
    letter-spacing: 1px;
    font-weight: 400;
    
}

.btvideo {
    margin-top: 16px;
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    height: 2rem;
    padding: .4em 1.8em;
    font-size: .8em;
    border-radius: 8px;
    background: #0D0D0D;
}

.tbverde {
    background: #12C15D;
    border-color: #12C15D;
    color: #0D0D0D;
}

.tbvermelho {
    background: #C12A12;
    border-color: #C12A12;
    color: #0D0D0D;
}

.tbazul {
    background: #221445;
    border-color: #221445;
    color: white;
}

.tbamarelo {
    background: #FCDB35;
    border-color: #FCDB35;
    color: #0D0D0D;
}

.tbcinza {
    background: gray;
    border-color: gray;
    color: #0D0D0D;
}

.tbverde:hover {
    color: white;
    background: #221445;
    border-color: #221445;
}

.tbvermelho:hover {
    color: white;
    background: #221445;
    border-color: #221445;
}

.tbazul:hover {
    color: white;
    background: #221445;
    border-color: #221445;
}

.tbamarelo:hover {
    color: white;
    background: #221445;
    border-color: #221445;
}

.tbcinza:hover {
    color: white;
    background: #221445;
    border-color: #221445;
}

.item1 {

    width: 100%;

    grid-area: gvideo;
    margin: auto;
    border-radius: 8px;



}

.item4 {

    grid-area: gvideo2;

}

.item2 {

    grid-area: gchat;
    border-radius: 8px;
    height: 90%;


}


.mdct2 {

    margin: 1rem auto;
    height: 90%;
}

.mdct {

    display: grid;
    grid-template-areas: 'gvideo' 'gchat';

    grid-gap: 24px;
}

@media (min-width: 900px) {
    .mdct {
        grid-template-areas: 'gvideo gvideo gchat';

    }

    .xtudo {
        margin-left: 4rem;
        margin-right: 4rem;
    }

}

.mdct88 {
    display: grid;
    grid-template-areas: 'gvideo';

}

.mdct89 {
    display: grid;
    grid-template-areas: 'gvideo2';

}