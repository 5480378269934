.conteudo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.conteudocol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.conteudo2 {
  width: 400px;

  padding-right: 1rem;
  padding-left: 1rem;
}

@media (min-width: 700px) {
  .conteudo2 {
    width: 600px;
  }
}

@media (min-width: 980px) {
  .conteudo2 {
    width: 700px;
  }
}

@media (min-width: 1400px) {
  .conteudo2 {
    width: 1200px;
  }
}