.Titulo2 {
    color: red;
}

.xtudo {
    margin-left: 2rem;
    margin-right: 2rem;
}

.margem {

    padding: 0rem 5rem 0rem 5rem;
}