.container {
  width: 1fr;
  min-width: 240px;
  margin: 0 auto;

  overflow: hidden;
}

.chat-container {
  height: 30vh;
  overflow: auto;

}


@media (min-width: 900px) {
  .chat-container {
    height: 60vh;


  }

  .container {
    width: 1fr;
    min-width: 380px;
  }

}

.chath {
  display: none;
}