.suporte {
    margin-top: 2rem;
    line-height: 1.4;
    letter-spacing: 2px;
    font-weight: 200;
}

.Tituloa2 {
    color: #0D0D0D;
}

.SpGrid {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0 auto;
    grid-gap: 24px;
}

.SpContato {

    margin-top: 40px;
    text-align: center;
}

.Spa {
    color: #0D0D0D;
}


.xtudol {
    margin-left: 2rem;
    margin-right: 2rem;
}



@media (min-width: 700px) {
    .SpGrid {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 980px) {
    .SpGrid {
        grid-template-columns: 1fr 1fr;
    }

    .xtudol {
        margin-left: 4rem;
        margin-right: 4rem;
    }
}

@media (min-width: 1400px) {
    .SpGrid {
        grid-template-columns: 1fr 1fr 1fr;
    }
}