.cabecalho {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 7rem;
    align-items: center;
    border-color: #221445;
    border-style: solid;
    border-width: 0px 0px 2px 0px;
    margin-bottom: 24px;
}

@media (min-width: 700px) {
    .cabecalho {
        padding-left: 5rem;
        padding-right: 5rem;
    }
}

.navbox {
    width: 160px;
}

.logo {
    height: 3rem;



}

@media (min-width: 700px) {
    .logo {
        height: 6rem;



    }
}




.navbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.btcadastrar {

    margin-top: 16px;
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    height: 1.8rem;
    width: 6rem;
    padding: .2em 1em;
    font-size: .8em;
    border-radius: 8px;
    color: #0D0D0D;
    background: #F2F2F2;
    border-color: transparent;
}

.btcadastrar:hover {

    color: #F2F2F2;
    background: #221445;
    border-color: #221445;

}



@media (min-width: 700px) {
    .btcadastrar {
        height: 2rem;
        width: 8rem;
        padding: .4em 1.8em;
        font-size: .8em;


    }
}


.btsair {

    margin-top: 16px;
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    height: 1.8rem;
    width: 6rem;
    padding: .2em 1em;
    font-size: .8em;
    border-radius: 8px;
    color: #F2F2F2;
    background: #0D0D0D;
    border-color: #0D0D0D;
}

.btsair:hover {
    color: #F2F2F2;
    background: #221445;
    border-color: #221445;
}


@media (min-width: 700px) {
    .btsair {
        height: 2rem;
        width: 8rem;
        padding: .4em 1.8em;
        font-size: .8em;


    }
}