.Tituloa3 {
  color: purple;
}

.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 16px;
  transition: 0.4s;
}

.active,
.accordion:hover {
  background-color: #ccc;
}

.panel {
  padding: 0 24px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

p {
  text-align: justify;
}

ul {
  margin-top: 1em;

}

ul li {
  margin: 1em;

  text-align: justify;
}

ol {
  margin-top: 1em;
  counter-reset: primary;

}

ol ol {

  margin-top: 1em;
  counter-reset: secondary;

}

ol ol ol {

  margin-top: 1em;
  counter-reset: tertiary;

}

ol li {
  margin: 1em;
  display: block;
  text-align: justify;
}

ol.list-root li:before {

  content: counters(primary, ".", decimal) ". ";
  counter-increment: primary;
}

ol.list-root li ol li:before {

  content: counters(primary, ".", decimal) "." counters(secondary, ".", decimal) ". ";
  counter-increment: secondary;

}

ol.list-root li ol li ol li:before {

  content: counters(primary, ".", decimal) "." counters(secondary, ".", decimal) "." counters(tertiary, ".", decimal) ". ";
  counter-increment: tertiary;

}

.xtudol {
  margin-left: 2rem;
  margin-right: 2rem;
}

@media (min-width: 980px) {
  .xtudol {
    margin-left: 4rem;
    margin-right: 4rem;
  }
}