.carousel-wrapper {
  width: 80%;
  margin: auto;
}

.carousel .slide img {
  width: 32rem;
  height: 18rem;
  object-fit: cover;
  border-radius: 8px;
}

.carousel .legend {
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 0 0 10px 10px;
}