.lvideoplay {
    width: 100%;
    height: auto;
    max-height: 40vh;
}

@media (min-width: 900px) {
    .lvideoplay {
        max-height: 80vh;
    }
}