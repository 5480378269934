.sobre {
    margin-top: 2rem;
    line-height: 1.4;
    letter-spacing: 2px;
    font-weight: 200;
}

.tverde {
    color: #12C15D;
}

.tvermelho {
    color: #C12A12;
}

.tazul {
    color: #4D51D6;
}

.tamarelo {
    color: #FCDB35;
}

.xtudol {
    margin-left: 2rem;
    margin-right: 2rem;
}

@media (min-width: 980px) {
    .xtudol {
        margin-left: 4rem;
        margin-right: 4rem;
    }
}